<template>
  <page-container title="项目建设规模情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="项目建设单位" prop="constructe_unit">
              <a-input v-model.trim="queryParams.constructe_unit" placeholder="请输入项目建设单位"></a-input>
            </a-form-model-item>

            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="项目地址" prop="address">
              <a-input v-model="queryParams.address" placeholder="请输入项目地址"></a-input>
            </a-form-model-item>
            <a-form-model-item label="建设用地面积(㎡)" prop="construction_land_area">
              <a-input v-model="queryParams.construction_land_area" placeholder="请输入建设用地面积(㎡)"></a-input>
            </a-form-model-item>
            <a-form-model-item label="栋数" prop="building">
              <a-input v-model="queryParams.building" placeholder="请输入栋数"></a-input>
            </a-form-model-item>
            <a-form-model-item label="总户数" prop="household">
              <a-input v-model="queryParams.household" placeholder="请输入总户数"></a-input>
            </a-form-model-item>
            <a-form-model-item label="容积率" prop="plot_ratio">
              <a-input v-model="queryParams.plot_ratio" placeholder="请输入容积率"></a-input>
            </a-form-model-item>
            <a-form-model-item label="总建筑面积(㎡)" prop="floor_space">
              <a-input v-model="queryParams.floor_space" placeholder="请输入总建筑面积(㎡)"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">项目建设规模情况列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <!--          <span slot="status" slot-scope="text" class="projectInfoScales_status-view">-->
          <!--            <span class="dot" :class="{'red': text=='1'}"></span>-->
          <!--            <span class="text">{{ text == '1' ? '项目建设规模' : '结清' }}</span>-->
          <!--          </span>-->
          <span slot="people_type" slot-scope="text" class="projectInfoScales_people_type-view">
            <span class="dot" :class="{'green': text===1}"></span>
            <span class="text">{{ text === 0 ? '使用人' : '业主' }}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改项目建设规模页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="项目建设单位" prop="constructe_unit">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.constructe_unit" placeholder="请输入项目建设单位"></a-input>
        </a-form-model-item>
        <a-form-model-item label="项目地址" prop="address">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.address" placeholder="请输入项目地址"></a-input>
        </a-form-model-item>
        <a-form-model-item label="建设用地面积(㎡)" prop="construction_land_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.construction_land_area" placeholder="请输入建设用地面积(㎡)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="栋数" prop="building">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.building" placeholder="请输入栋数"></a-input>
        </a-form-model-item>
        <a-form-model-item label="总户数" prop="household">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.household" placeholder="请输入总户数"></a-input>
        </a-form-model-item>
        <a-form-model-item label="容积率" prop="plot_ratio">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.plot_ratio" placeholder="请输入容积率"></a-input>
        </a-form-model-item>
        <a-form-model-item label="总建筑面积(㎡)" prop="floor_space">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.floor_space" placeholder="请输入总建筑面积(㎡)"></a-input>
        </a-form-model-item>

        <a-form-model-item label="绿地面积(㎡)" prop="green_area">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.green_area" placeholder="请输入绿地面积(㎡)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地上建筑面积(㎡)" prop="land_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.land_area" placeholder="请输入地上建筑面积(㎡)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计容面积(㎡)" prop="volumetric_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.volumetric_area" placeholder="请输入计容面积(㎡)"></a-input>
        </a-form-model-item>

        <a-form-model-item label="物业用房（㎡）" prop="property_room_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.property_room_area" placeholder="请输入物业用房（㎡）"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地下建筑面积(㎡)" prop="underground_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.underground_area" placeholder="请输入地下建筑面积(㎡)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="住宅面积" prop="residential_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.residential_area" placeholder="请输入住宅面积"></a-input>
        </a-form-model-item>
        <a-form-model-item label="商业面积(㎡)" prop="commercial_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.commercial_area" placeholder="请输入商业面积(㎡)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地下商业建筑面积(㎡)" prop="underground_commercial_area">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.underground_commercial_area" placeholder="请输入地下商业建筑面积(㎡)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地下停车建筑面积（㎡）" prop="underground_park_area">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.underground_park_area" placeholder="请输入地下停车建筑面积（㎡）"></a-input>
        </a-form-model-item>
        <a-form-model-item label="其他面积(㎡)" prop="other_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.other_area" placeholder="请输入其他面积(㎡)"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地上停车位（个）" prop="land_parking_nums">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.land_parking_nums" placeholder="请输入地上停车位（个）"></a-input>
        </a-form-model-item>
        <a-form-model-item label="地下停车位（个）" prop="underground_parking_nums">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.underground_parking_nums" placeholder="请输入地下停车位（个）"></a-input>
        </a-form-model-item>
        <a-form-model-item label="社区用房（㎡）" prop="community_room_area">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.community_room_area" placeholder="请输入社区用房（㎡）"></a-input>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceProjectInfoScale,
  getProjectInfoScaleListByCondition,
  deleteOneProjectInfoScale,
  editProjectInfoScale
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {userdep} from "V/dataAnalysis/performance/minins/userdep";

export default {
  name: 'projectInfoScale',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '项目情况简介',
          path: ''
        },
        {
          name: '项目建设规模',
          path: ''
        }
      ],
      queryParams: {
        constructe_unit:'',
        name:'',
        address:'',
        construction_land_area:'',
        plot_ratio:'',
        building:'',
        household:'',
        floor_space:'',
        green_area:'',
        land_area:'',
        volumetric_area:'',
        underground_area:'',
        residential_area:'',
        commercial_area:'',
        underground_commercial_area:'',
        underground_park_area:'',
        other_area:'',
        land_parking_nums:'',
        underground_parking_nums:'',
        property_room_area:'',
        community_room_area:'',
        userdepid:'',
        selectDeptList:[]
      },
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '项目地址',
          dataIndex: 'address',
          key: 'address'
        },
        {
          title: '项目建设单位',
          dataIndex: 'constructe_unit',
          key: 'constructe_unit'
        },
        {
          title: '建设用地面积(㎡)',
          dataIndex: 'construction_land_area',
          key: 'construction_land_area'
        },
        {
          title: '栋数',
          dataIndex: 'building',
          key: 'building'
        },
        {
          title: '总户数',
          dataIndex: 'household',
          key: 'household'
        },
        {
          title: '容积率',
          dataIndex: 'plot_ratio',
          key: 'plot_ratio'
        },
        {
          title: '总建筑面积(㎡)',
          dataIndex: 'floor_space',
          key: 'floor_space'
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        constructe_unit:'',
        name:'',
        address:'',
        construction_land_area:'',
        plot_ratio:'',
        building:'',
        household:'',
        floor_space:'',
        green_area:'',
        land_area:'',
        volumetric_area:'',
        underground_area:'',
        residential_area:'',
        commercial_area:'',
        underground_commercial_area:'',
        underground_park_area:'',
        other_area:'',
        land_parking_nums:'',
        underground_parking_nums:'',
        property_room_area:'',
        community_room_area:'',
        userdepid:'',
        selectDeptList:[],
      },
      modalRules: {
        constructe_unit:[{required: true, message: '请输入项目建设单位'}],
        address:[{required: true, message: '请输入项目地址'}],
        construction_land_area:[{required: true, message: '请输入项目建设用地面积'}],
        plot_ratio:[{required: true, message: '请输入容积率'}],
        building:[{required: true, message: '请输入栋数'}],
        household:[{required: true, message: '请输入总户数'}],
        floor_space:[{required: true, message: '请输入总建筑面积'}],
        green_area:[{required: true, message: '请输入绿地面积'}],
        land_area:[{required: true, message: '请输入地上建筑面积'}],
        volumetric_area:[{required: true, message: '请输入计容面积'}],
        // underground_area:[{required: true, message: '请输入地下建筑面积'}],
        // residential_area:[{required: true, message: '请输入住宅面积'}],
        // commercial_area:[{required: true, message: '请输入商业面积'}],
        // underground_commercial_area:[{required: true, message: '请输入地下商业建筑面积'}],
        // underground_park_area:[{required: true, message: '请输入地下停车建筑面积'}],
        // other_area:[{required: true, message: '请输入其他面积'}],
        // land_parking_nums:[{required: true, message: '请输入地上停车位数量'}],
        // underground_parking_nums:[{required: true, message: '请输入地下停车位数量'}],
        property_room_area:[{required: true, message: '请输入物业用房面积'}],
        //community_room_area:[{required: true, message: '请输入社区用房面积'}],

        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedProjectInfoScale:null,
      theUserdepId:""
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      let title = '';
      if (this.modalType === 'add') {
        title = '新增';
      } else if (this.modalType === 'edit') {
        title = '修改';
      }  else if (this.modalType === 'detail') {
        title = '详情';
      } else {
        title = '';
      }
      return title;
    },
    //查询项目建设规模单时间
    query_begin_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[0]) {
        return moment(this.queryParams.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    query_endtime() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[1]) {
        return moment(this.queryParams.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    //添加项目建设规模单时间
    add_happen_time() {
      if (this.modalForm.happen_time) {
        return moment(this.modalForm.happen_time).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        let _userdepid=this.modalForm.userdepid;
        this.modalForm.userdepid = val.join('|')
        //检查记录中是否已经有了这个项目的记录
        this.getDataByUserdepId(_userdepid)
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        console.log(this.queryParams.userdepid)
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;

      // let userdepid = this.queryParams.userdepid;
      // if(userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        begin_time: this.query_begin_time,
        end_time:this.query_endtime,
        // userdep_id:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
        // userdepid:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
      };
      getProjectInfoScaleListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          // this.tableData = res.item?res.item.map(item => ({
          //   ...item,
          //   time_interval: item.status===1?item.begin_time+'~至今':item.begin_time+'~'+item.end_time
          // })):[]
          this.tableData = res.item?res.item:[]
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0

        this.modalForm.constructe_unit=''
        this.modalForm.name=''
        this.modalForm.address=''
        this.modalForm.construction_land_area=''
        this.modalForm.plot_ratio=''
        this.modalForm.building=''
        this.modalForm.household=''
        this.modalForm.floor_space=''
        this.modalForm.green_area=''
        this.modalForm.land_area=''
        this.modalForm.volumetric_area=''
        this.modalForm.underground_area=''
        this.modalForm.residential_area=''
        this.modalForm.commercial_area=''
        this.modalForm.underground_commercial_area=''
        this.modalForm.underground_park_area=''
        this.modalForm.other_area=''
        this.modalForm.land_parking_nums=''
        this.modalForm.underground_parking_nums=''
        this.modalForm.property_room_area=''
        this.modalForm.community_room_area=''
        
        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true
        this.modalForm.id=record.id
        this.modalForm.constructe_unit=record.constructe_unit
        this.modalForm.name=record.name
        this.modalForm.address=record.address
        this.modalForm.construction_land_area=record.construction_land_area
        this.modalForm.plot_ratio=record.plot_ratio
        this.modalForm.building=record.building
        this.modalForm.household=record.household
        this.modalForm.floor_space=record.floor_space
        this.modalForm.green_area=record.green_area
        this.modalForm.land_area=record.land_area
        this.modalForm.volumetric_area=record.volumetric_area
        this.modalForm.underground_area=record.underground_area
        this.modalForm.residential_area=record.residential_area
        this.modalForm.commercial_area=record.commercial_area
        this.modalForm.underground_commercial_area=record.underground_commercial_area
        this.modalForm.underground_park_area=record.underground_park_area
        this.modalForm.other_area=record.other_area
        this.modalForm.land_parking_nums=record.land_parking_nums
        this.modalForm.underground_parking_nums=record.underground_parking_nums
        this.modalForm.property_room_area=record.property_room_area
        this.modalForm.community_room_area=record.community_room_area
        this.modalForm.userdepid = record.userdep_id
        this.theUserdepId = record.userdep_id
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(projectInfoScale_id) {
      if (projectInfoScale_id) {
        let params = {
          projectInfoScale_id
        };
        deleteOneProjectInfoScale(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑项目建设规模单
    addOrEdit() {
      this.showLoading();
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {
        constructe_unit:this.modalForm.constructe_unit,
        name:this.modalForm.name,
        address:this.modalForm.address,
        construction_land_area:this.modalForm.construction_land_area,
        plot_ratio:this.modalForm.plot_ratio,
        building:this.modalForm.building,
        household:this.modalForm.household,
        floor_space:this.modalForm.floor_space,
        green_area:this.modalForm.green_area,
        land_area:this.modalForm.land_area,
        volumetric_area:this.modalForm.volumetric_area,
        underground_area:this.modalForm.underground_area,
        residential_area:this.modalForm.residential_area,
        commercial_area:this.modalForm.commercial_area,
        underground_commercial_area:this.modalForm.underground_commercial_area,
        underground_park_area:this.modalForm.underground_park_area,
        other_area:this.modalForm.other_area,
        land_parking_nums:this.modalForm.land_parking_nums,
        underground_parking_nums:this.modalForm.underground_parking_nums,
        property_room_area:this.modalForm.property_room_area,
        community_room_area:this.modalForm.community_room_area,
        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        //console.log(params)
        addPerformanceProjectInfoScale(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editProjectInfoScale(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedProjectInfoScale = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedProjectInfoScale)
      if ('edit' === type) {
        this.showModal('edit', id, this.selectedProjectInfoScale)
      }  else if ('detail' === type) {
        this.showModal('detail', id, this.selectedProjectInfoScale)
      }else if ('delete' === type) {
        this.deleteConfirm(id, this.selectedProjectInfoScale)
      }
    },
    handleClickRow(record,index){
      //console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    getDataByUserdepId(_userdepid) {
      console.log(this.theUserdepId)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      console.log(Number(userdepid))
      if(String(_userdepid)!==userdepid){
        let params = {
          userdep_id:userdepid,
        };
        let lauch=false;
        if(this.modalType === 'edit' && this.theUserdepId!==Number(userdepid)){
          lauch=true
        }
        if(this.modalType === 'add'){
          lauch=true
        }
        if(lauch){
          getProjectInfoScaleListByCondition(params).then(res => {
            if (res && res.returncode === '0') {
              if(Number(res.count)>0){
                this.$message.error("项目已存在，新增失败，请核查，谢谢！");
                if(this.modalType === 'add'){
                  this.modalForm.selectDeptList=[]
                  this.modalForm.userdepid=''
                }
              }
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.projectInfoScales_status-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #65ff18;
    border-radius: 50%;
    &.red {
      background-color: #e70c0c;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
.projectInfoScales_people_type-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #1859ff;
    border-radius: 50%;
    &.green {
      background-color: #65ff18;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
</style>